import { DocumentType, MediaType } from '@innedit/innedit-type';
import { MediaData } from 'packages/innedit';
import React, { FC, SyntheticEvent, useEffect, useState } from 'react';

import ListCard from '~/containers/Espace/Card';
import { ListItemProps } from '~/containers/Espace/List/props';
import nextPhoto from '~/images/next-photo.svg';
import ImagePhoto from '~/images/Photo';
import colors from '~/styles/colors';

const ListCardMedia: FC<ListItemProps<MediaType, MediaData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<MediaType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return (
      <li className="flex flex-col !p-0 rounded">
        <div className="relative flex-auto flex flex-col-reverse bg-white-50 pb-full">
          <div className="absolute top-0 right-0 bottom-0 left-0 flex items-center justify-center">
            <ImagePhoto color={colors.light[600]} size={100} />
          </div>
        </div>
      </li>
    );
  }

  let photo;
  try {
    if (doc.fullPath) {
      switch (doc.type) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/webp': {
          photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
            doc.fullPath
          }?w=320&h=320&c=max`;
          break;
        }

        case 'image/svg+xml':
        default: {
          photo = `${String(process.env.GATSBY_IMAGES_DOMAIN_NAME)}/${
            doc.fullPath
          }`;
          break;
        }
      }
    } else {
      photo = nextPhoto;
    }
  } catch (e) {
    photo = nextPhoto;
  }

  const handleOnClick = (event: SyntheticEvent<HTMLElement>) => {
    onClick(event);
  };

  return (
    <ListCard
      displayEdit={false}
      displayId={false}
      doc={doc}
      index={index}
      onClick={handleOnClick}
    >
      <figure
        className="card-image aspect-ratio aspect-ratio--square"
        data-id={doc.id}
        onClick={handleOnClick}
        role="presentation"
      >
        {photo && (
          <img alt="Chargement" className="aspect-ratio__content" src={photo} />
        )}
      </figure>
    </ListCard>
  );
};

export default ListCardMedia;
