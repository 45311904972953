import { PageProps } from 'gatsby';
import { MediaData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Card from '~/components/List/Card/Media';
import Item from '~/components/List/Item/Media';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const MediasPage: FC<
  PageProps & EspaceProps & UserProps & { pageContext: { tab?: number } }
> = ({
  location,
  espace,
  user,
  pageContext: { tab },
  params: { espaceId },
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemGrid={Card}
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espaceId}/medias/`}
          model={
            new MediaData({
              espaceId,
            })
          }
          pathname={location.pathname}
          removeAddButton
          search={location.search}
          tabIndex={tab}
          title={t('medias.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(MediasPage);
